import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  BusinessTableChunk,
  CardType,
  CustomTableChunk,
  InfoBlockField,
  NewBeneficiaryValues,
  NewBranchValues,
  PersonalaizedTariffResponse,
} from '../types/add_commerce.types';
import { BusinessSectorEnum } from '../types/enums/business-sector.enum';
import { CurrencyEnum } from '../types/enums/currency.enum';
import { DocumentTypeEnum } from '../types/enums/document-type.enum';
import { TaxPayerTypeEnum } from '../types/enums/taxpayer-type.enum';
import { TerminalTypesEnum } from '../types/enums/terminal-types.enum';
import {
  beneficiaryFields,
  branchFields,
  currencies,
  documentTypes,
  newBeneficiary,
  terminalNetworkOptions,
} from './data';

export const isEmptyField = (value: any) => {
  if (value === null || value === undefined) {
    return '-';
  }
  return value;
};

export const updateBlockFields = (fieldNames: string[] | string, fieldValues: any[] | any) => {
  if (Array.isArray(fieldNames)) {
    return fieldNames.map((fieldname, index) => ({
      name: fieldname,
      value: fieldValues[index],
    }));
  }
  return [{ name: fieldNames, value: fieldValues }];
};

export const updateDuplicatedBlockFields = (dataGroup: string, data: any) => {
  if (dataGroup === 'branch') {
    const branchValues = [
      data.fantasyName,
      data?.city?.state?.name,
      data?.city?.name,
      `${data.addressStreet} ${data.addressNumber}`,
      isEmptyField(data.addressFlatNumber),
      isEmptyField(data.addressSolar),
      isEmptyField(data.addressPlot),
    ];
    return updateBlockFields(branchFields, branchValues);
  }
  if (dataGroup === 'beneficiary') {
    const beneficiaryValues = [
      documentTypes.find(
        (document: InfoBlockField) =>
          document.value === DocumentTypeEnum[data.documentType as keyof typeof DocumentTypeEnum],
      )?.name,
      data.documentNumber,
      `${data.firstName} ${data.lastName}`,
      data.participationPercentage,
      data.isPep ? 'Si' : 'No',
      data.isRelative ? 'Si' : 'No',
    ];
    const beneficiaryFieldsUpdated = updateBlockFields(beneficiaryFields, beneficiaryValues);

    if (
      data.relativeDocumentType &&
      data.relativeDocumentNumber &&
      data.relativeFirstName &&
      data.relativeLastName
    ) {
      const familyFields = [
        {
          name: 'Tipo de documento',
          value: documentTypes.find(
            (document: InfoBlockField) =>
              document.value === DocumentTypeEnum[data.relativeDocumentType as keyof typeof DocumentTypeEnum],
          )?.name,
        },
        { name: 'Nº de documento', value: data.relativeDocumentNumber },
        { name: 'Nombre y apellido', value: `${data.relativeFirstName} ${data.relativeLastName}` },
      ];
      return [...beneficiaryFieldsUpdated, ...familyFields];
    }
    return beneficiaryFieldsUpdated;
  }
  return [];
};

export const getBeneficiaries = (branches: NewBranchValues[]): NewBeneficiaryValues[] => {
  const newLocalBeneficiary = [{ ...newBeneficiary, localId: uuidv4() }];

  if (!branches) return newLocalBeneficiary;

  const branchesWithBeneficiaries = branches.filter((branch) => !!branch.beneficiaries?.length);

  if (!branchesWithBeneficiaries.length) return newLocalBeneficiary;
  const beneficiaries = branchesWithBeneficiaries.flatMap((branches) => branches.beneficiaries!);
  const response = [];
  let i: number = 0;
  let j: number = 1;
  let isUnique: boolean = true;

  if (beneficiaries.length > 1) {
    while (i < beneficiaries.length) {
      const currentBeneficiary = beneficiaries[i];

      if (j < beneficiaries.length) {
        if (currentBeneficiary.id === beneficiaries[j].id) {
          beneficiaries[j].branchOfficeId = 0;
          isUnique = false;
        }
        j++;
      } else {
        if (!currentBeneficiary.branchOfficeId && currentBeneficiary.branchOfficeId !== 0)
          currentBeneficiary.branchOfficeId =
            currentBeneficiary.beneficiary_branch_office?.branchOfficeId || null;
        if (isUnique) response.push(currentBeneficiary);
        i++;
        j = i + 1;
        isUnique = true;
      }
    }
  } else {
    beneficiaries[0].branchOfficeId = beneficiaries[0].beneficiary_branch_office?.branchOfficeId || null;
    response.push(beneficiaries[0]);
  }

  return response;
};

export const getTerminalTypeValue = (type: string) => {
  switch (type) {
    case 'NAD':
      return TerminalTypesEnum.NAD;
    case 'POS2000':
      return TerminalTypesEnum.POS2000;
    case 'SCANNTECH':
      return TerminalTypesEnum.SCANNTECH;
    case 'RESONANCE':
      return TerminalTypesEnum.RESONANCE;
    case 'HANDY':
      return TerminalTypesEnum.HANDY;
    case 'SISTECO':
      return TerminalTypesEnum.SISTECO;
    case 'GEOCOM':
      return TerminalTypesEnum.GEOCOM;
    case 'PRANASYS':
      return TerminalTypesEnum.PRANASYS;
    case 'FISERV':
      return TerminalTypesEnum.FISERV;
    case 'OCA':
      return TerminalTypesEnum.OCA;
    case 'VISANET':
      return TerminalTypesEnum.VISANET;
    default:
      return '';
  }
};

export const triggerToasts = (response: any, autoClose: false | undefined = undefined) => {
  if (response.status === 200) {
    toast.success(response.message);
  } else if (response.status === 401) {
    localStorage.removeItem('fullName');
  } else {
    if (typeof response === 'string' && response) {
      toast.error(response, { autoClose: autoClose });
    } else {
      if (!response?.data?.data?.errors?.length && !response.data?.showMessage?.ES) {
        toast.error('Ha ocurrido un error');
      } else {
        if (response?.data?.data?.errors?.length) {
          response?.data?.data?.errors?.map((error: any) => error?.msg && toast.error(error.msg));
        }
        if (response?.data?.showMessage?.ES) {
          toast.error(response.data.showMessage.ES);
        }
      }
    }
  }
  toast.clearWaitingQueue();
};

export const getEnumName = (enumType: string, enumString: string): string => {
  let dataArray: InfoBlockField[] | undefined;
  let enumRef: any;

  switch (enumType) {
    case 'currency':
      dataArray = currencies;
      enumRef = CurrencyEnum;
      break;
    case 'terminal':
      dataArray = terminalNetworkOptions;
      enumRef = TerminalTypesEnum;
      break;
    default:
      break;
  }

  if (dataArray && enumRef) {
    const enumName = dataArray?.find(
      (element) => element.value === enumRef[enumString as keyof typeof enumRef],
    )?.name;
    return enumName || '';
  }
  return '';
};

export const getEnumTypeValue = (dataType: string, data: any) => {
  let enumStringArray;

  if (dataType === 'taxPayer') {
    enumStringArray = [
      TaxPayerTypeEnum[TaxPayerTypeEnum.PEQUENA_EMPRESA],
      TaxPayerTypeEnum[TaxPayerTypeEnum.MONOTRIBUTO],
      TaxPayerTypeEnum[TaxPayerTypeEnum.MONOTRIBUTISTAS_MIDES],
    ];

    return data.id;
    //return findEnumByString(enumStringArray, data?.description, TaxPayerTypeEnum);
  }
  if (dataType === 'businessCategory') {
    enumStringArray = [
      BusinessSectorEnum[BusinessSectorEnum.COMBUSTIBLES],
      BusinessSectorEnum[BusinessSectorEnum.HIPERMERCADOS_SUPERMERCADOS_ALMACENES],
      BusinessSectorEnum[BusinessSectorEnum.RESTAURANTES],
    ];

    return findEnumByString(enumStringArray, data?.name, BusinessSectorEnum);
  }

  if (dataType === 'currency') {
    enumStringArray = [CurrencyEnum[CurrencyEnum.URUGUAYAN_PESOS], CurrencyEnum[CurrencyEnum.US_DOLLAR]];

    return findEnumByString(enumStringArray, data?.name, CurrencyEnum);
  }
};

const findEnumByString = (enumStringArray: any[], dataName: string, enumRef: any) => {
  const enumStringMatch = enumStringArray.find((enumString) =>
    enumString.includes(dataName?.replace(' - ', ' ').toUpperCase().split(' ').join('_')),
  );

  if (enumStringMatch) {
    return enumRef[enumStringMatch as keyof typeof enumRef];
  }
  // return enumRef === TaxPayerTypeEnum ? TaxPayerTypeEnum.OTROS : BusinessSectorEnum.TODOS;
  return enumRef === BusinessSectorEnum && BusinessSectorEnum.TODOS;
};

export const getFilenameFromUrl = (fileUrl: string): string => {
  if (!!fileUrl) {
    const splitFileUrl: string[] = fileUrl.split('/');
    const fileName: number = splitFileUrl.length - 1;
    return splitFileUrl[fileName];
  }
  return '';
};

export const formatTableData = (tableData: any): BusinessTableChunk[] => {
  const categoriesArray = [
    'creditoInternacional',
    'creditoLocal',
    'tarjetaDebitoMcMaestroPrepagaInternacional',
    'prepagaLocal',
    'debitoLocal',
  ];

  const tableChunks: BusinessTableChunk[] = Object.keys(tableData)
    .filter((category) => categoriesArray.includes(category))
    .map((category) => {
      let categoryFormatted: string;
      if (category === 'creditoInternacional') categoryFormatted = 'Credito Internacional';
      if (category === 'creditoLocal') categoryFormatted = 'Credito Local';
      if (category === 'tarjetaDebitoMcMaestroPrepagaInternacional')
        categoryFormatted = 'Tarjeta de Débito MC/Maestro/Prepaga Internacional';
      if (category === 'prepagaLocal') categoryFormatted = 'Tarjeta Prepaga Local';
      if (category === 'debitoLocal') categoryFormatted = 'Debito Local';

      return {
        category: categoryFormatted!,
        rows: Array.isArray(tableData[category])
          ? tableData[category]?.map((categoryData: any) => ({
              plan: categoryData.plan,
              currency: categoryData.moneda,
              fees: categoryData.comision,
              deferred: categoryData.diferido,
            }))
          : [
              {
                plan: tableData[category].plan,
                currency: tableData[category].moneda,
                fees: tableData[category].comision,
                deferred: tableData[category].diferido,
              },
            ],
      };
    });

  return tableChunks;
};

export const sortArrayAlphabetically = (array: any[], key: string) => {
  if (array.length && key) {
    return array.sort((a: any, b: any) => (a[key] && b[key] ? a[key].localeCompare(b[key]) : 0));
  }
  return array;
};

export const dataToUpperCase = (data: any): any => {
  for (const key in data) {
    typeof data[key] === 'string' && (data[key] = data[key].toUpperCase());
  }
  return data;
};

export const capitalize = (text: string | undefined): string => {
  if (!!text) {
    return text
      .toLowerCase()
      ?.split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return '';
};

export const addPercentaje = (dataPiece: string | number) => {
  return dataPiece.toString().concat('%');
};

const formattCategories = (category: string) => {
  switch (category) {
    case 'creditoInternacionalVISA':
    case 'creditoInternacionalMASTER':
    case 'tarjetaDebitoMcMaestroPrepagaInternacionalVISA':
    case 'tarjetaDebitoMcMaestroPrepagaInternacionalMASTER':
      return 'Venta con tarjeta internacional';
    case 'creditoLocalVISA':
    case 'creditoLocalMASTER':
    case 'prepagaLocalVISA':
    case 'prepagaLocalMASTER':
    case 'debitoLocalVISA':
    case 'debitoLocalMASTER':
      return 'Venta con tarjeta local';
    default:
      return '';
  }
};

const formattTitle = (category: string) => {
  switch (category) {
    case 'creditoLocalVISA':
    case 'creditoLocalMASTER':
      return 'Crédito contado';
    case 'creditoInternacionalVISA':
    case 'creditoInternacionalMASTER':
      return 'Extranjeros crédito';
    case 'tarjetaDebitoMcMaestroPrepagaInternacionalVISA':
    case 'tarjetaDebitoMcMaestroPrepagaInternacionalMASTER':
      return 'Extranjeros débito';
    case 'debitoLocalVISA':
    case 'debitoLocalMASTER':
      return 'Débito';
    case 'prepagaLocalVISA':
    case 'prepagaLocalMASTER':
      return 'Prepagas';
    default:
      return '';
  }
};

export const formatTableDataForTariffsVisa = (tableData: any): { title: string; value: string }[] => {
  const categoriesArray = [
    'creditoInternacionalVISA',
    'creditoLocalVISA',
    'tarjetaDebitoMcMaestroPrepagaInternacionalVISA',
    'prepagaLocalVISA',
    'debitoLocalVISA',
  ];
  const tableChunks: { title: string; value: string }[] = Object.keys(tableData)
    .filter((category) => categoriesArray.includes(category))
    .map((category) => {
      const titleFormatted: string = formattTitle(category);
      if (Array.isArray(tableData[category])) {
        return tableData[category]?.map((categoryData: any) => ({
          title: titleFormatted,
          value: categoryData.comision,
        }));
      }

      return {
        title: titleFormatted,
        value: tableData[category].comision,
      };
    });

  return tableChunks.flat();
};

export const formatTableDataForBranchTariff = (rows: { plan: string; currency: number }[]) => {
  const formattedData = rows.map((row) => {
    return { title: row.plan, value: `${row.currency.toFixed(2)} %` };
  });

  return formattedData;
};

export const formatTableDataForTariffsMaster = (tableData: any): { title: string; value: string }[] => {
  const categoriesArray = [
    'creditoInternacionalMASTER',
    'creditoLocalMASTER',
    'tarjetaDebitoMcMaestroPrepagaInternacionalMASTER',
    'prepagaLocalMASTER',
    'debitoLocalMASTER',
  ];

  const tableChunks: { title: string; value: string }[] = Object.keys(tableData)
    .filter((category) => categoriesArray.includes(category))
    .map((category) => {
      const titleFormatted: string = formattTitle(category);
      if (Array.isArray(tableData[category])) {
        return tableData[category]?.map((categoryData: any) => ({
          title: titleFormatted,
          value: categoryData.comision,
        }));
      }

      return {
        title: titleFormatted,
        value: tableData[category].comision,
      };
    });

  return tableChunks.flat();
};

const formattCard = (card: CardType, formattedCard: Array<CustomTableChunk>): Array<CustomTableChunk> => {
  formattedCard[0].rows = [
    {
      plan: 'DÉBITO',
      currency: card.debit.max,
      fees: card.debit.max,
      deferred: card.debit.min,
      suffix: '%',
    },
    {
      plan: 'PREPAGO',
      currency: card.debit.max + 0.05,
      fees: card.debit.max + 0.05,
      deferred: card.debit.min + 0.05,
      suffix: '%',
    },
    {
      plan: 'CRÉDITO CONTADO',
      currency: card.cashCredit.max,
      fees: card.cashCredit.max,
      deferred: card.cashCredit.min,
      suffix: '%',
    },
    {
      plan: 'CRÉDITO CUOTAS',
      currency: card.installmentCredit.max,
      fees: card.installmentCredit.max,
      deferred: card.installmentCredit.min,
      suffix: '%',
    },
    {
      plan: 'Extranjeros DÉBITO',
      currency: 3.4,
      suffix: '%',
      fees: 3.4,
      deferred: 3.4,
    },
    {
      plan: 'Extranjeros CRÉDITO',
      currency: 3.4,
      suffix: '%',
      fees: 3.4,
      deferred: 3.4,
    },
  ];

  return formattedCard;
};

export const formatPersonalizedTable = (
  tableData: PersonalaizedTariffResponse,
): { mastercard: Array<CustomTableChunk>; visa: Array<CustomTableChunk> } => {
  const mastercard = tableData.mastercard;
  const visa = tableData.visa;

  const mastercardFormatted: Array<CustomTableChunk> = [{ rows: [] }];
  const visaFormatted: Array<CustomTableChunk> = [{ rows: [] }];

  const mastercardFormattedToReturn = formattCard(mastercard, mastercardFormatted);
  const visaFormattedToReturn = formattCard(visa, visaFormatted);

  return { mastercard: mastercardFormattedToReturn, visa: visaFormattedToReturn };
};
