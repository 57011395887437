import { ChangeEvent, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import RHFSelect from '../../../../../components/ReactHookFormComponents/Input/RHFSelect';
import { getTerminals, getTerminalsPrice } from '../../../../../services/commerce.service';
import {
  BusinessTableColumn,
  BusinessTableRow,
  CustomTableChunk,
  Integration,
} from '../../../../../types/add_commerce.types';
import CreateBranchSchemaType from '../../../../../types/branchOffice/branch-office-form';
import { BranchTerminals } from '../../../../../types/branchOffice/response';
import { integrations, terminalHeadings } from '../../../../../utils/data';
import PersonalaizedTariffTable from '../../../Conditions/PersonalaizedTariff';
import BranchCommerceCodes from './BranchCommerceCodes/BranchCommerceCodes';

interface Terminal {
  id: number;
  name: string;
  price: number;
}

interface NewAgeDataProductsProps {
  presettedTerminals?: BranchTerminals[];
}

function NewAgeDataProducts({ presettedTerminals }: NewAgeDataProductsProps) {
  const [terminals, setTerminals] = useState<CustomTableChunk[]>([]);
  const { control, setValue } = useFormContext<CreateBranchSchemaType>();
  const terminalsDataValues = useWatch({ control, name: 'nadProducts.terminals' });

  useEffect(() => {
    fetchTerminals();
  }, []);

  const fetchTerminals = async () => {
    const responseTerminals = await getTerminals();
    const tableFormatedTerminals: BusinessTableRow[] = responseTerminals.data.map((terminal: Terminal) => {
      const preSettedTerminal = presettedTerminals?.length
        ? presettedTerminals.find(
            (businessTerminal: BranchTerminals) => +businessTerminal.type === terminal.id,
          )
        : null;

      if (preSettedTerminal) {
        return {
          id: terminal.id.toString(),
          plan: terminal.name,
          currency: preSettedTerminal.quantity,
          fees: terminal.price,
          deferred: preSettedTerminal.price,
        };
      } else {
        return {
          id: terminal.id.toString(),
          plan: terminal.name,
          currency: 0,
          fees: terminal.price,
          deferred: 0,
        };
      }
    });

    const tableData: CustomTableChunk[] = [{ rows: tableFormatedTerminals }];
    setTerminals(tableData);
  };

  const handleChangePersonalaizedTable = async (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
    column: BusinessTableColumn,
  ) => {
    const newTableData = [...tableData];
    const { value } = event.target;
    const id = event.target.id.split('_')[1];
    const indexInRows = newTableData[0].rows.findIndex((row) => row?.id?.toString() === id);

    if (value) {
      const newTerminalsValues = await getTerminalsPrice(id, value);
      newTableData[0].rows[indexInRows].deferred = newTerminalsValues.data.price;
    }
    newTableData[0].rows[indexInRows][column] = value;

    const terminalsData = newTableData[0].rows.map((values) => {
      return {
        type: values.id!,
        quantity: +values.currency!,
        UIPrice: String(values.deferred)!,
      };
    });

    setValue('nadProducts.terminals', terminalsData);
  };

  return (
    <Container>
      <RHFSelect
        label="¿El comercio cuenta con facturación electrónica?"
        className="col-md-12"
        name="nadProducts.integration"
        required
        noPlaceholder
      >
        {integrations.map((integration: Integration, index: number) => (
          <option key={`${integration.value}_${index}`} value={integration.value}>
            {integration.label}
          </option>
        ))}
      </RHFSelect>
      <h4 className="branch-products-section-title">Nueva terminal New Age Data</h4>
      <PersonalaizedTariffTable
        setFieldValue={setValue}
        headings={terminalHeadings}
        tableData={terminals}
        field="nadProducts.terminals"
        handleChangePersonalaizedTable={handleChangePersonalaizedTable}
      />
      <h4 className="branch-products-section-title">Tipo de plan</h4>
      <Row>
        <RHFSelect
          className="col-md-4"
          name="nadProducts.terminalPlan.allowInstallments"
          label="Permite cuotas"
          required
        >
          <option value="yes">Si</option>
          <option value="no">No</option>
        </RHFSelect>
        <RHFSelect
          className="col-md-4"
          name="nadProducts.terminalPlan.includesTips"
          label="Incluye propina"
          required
        >
          <option value="yes">Si</option>
          <option value="no">No</option>
        </RHFSelect>
        <RHFSelect
          className="col-md-4"
          name="nadProducts.terminalPlan.ivaType"
          label="Tipo Tasa IVA"
          required
        >
          <option value="0">Básico</option>
          <option value="2">Exento</option>
          <option value="4">Mixto</option>
          <option value="1">Mínima</option>
        </RHFSelect>
      </Row>
      <h4 className="branch-products-section-title">Sub productos</h4>
      <Row>
        <p className="branch-products-section-sub-title">Seleccione según corresponda</p>
        <div className="col-md-9 d-flex">
          <label className="checkbox-label col-4 d-flex align-items-center mt-1" htmlFor="imSantander">
            <Controller
              name="nadProducts.hasImSantander"
              control={control}
              render={({ field }) => (
                <input
                  className="checkbox"
                  type="checkbox"
                  id="imSantander"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  checked={field.value}
                />
              )}
            />
            Soy Santander
          </label>
          <label className="checkbox-label col-4 d-flex align-items-center mt-1" htmlFor="pixPayments">
            <Controller
              name="nadProducts.hasPixPayments"
              control={control}
              render={({ field }) => (
                <input
                  className="checkbox"
                  type="checkbox"
                  id="pixPayments"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  checked={field.value}
                />
              )}
            />
            Cobros con Pix
          </label>
          <label className="checkbox-label col-4 d-flex align-items-center mt-1" htmlFor="amex">
            <Controller
              name="nadProducts.hasAmex"
              control={control}
              render={({ field }) => (
                <input
                  className="checkbox"
                  type="checkbox"
                  id="amex"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  checked={field.value}
                />
              )}
            />
            Amex (American Express)
          </label>
        </div>
      </Row>
      <Row className="branch-products-terminal-plan-container">
        <h4 className="branch-products-section-title">Código de comercio</h4>
        <p>
          Los códigos de comercio para Visa y MasterCard serán gestionados por Getnet. Puedes añadir otros
          códigos de comercio a la sucursal.
        </p>
        <BranchCommerceCodes />
      </Row>
    </Container>
  );
}

export default NewAgeDataProducts;
