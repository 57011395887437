import CreateBranchSchemaType from '../types/branchOffice/branch-office-form';
import { BranchCommerceCodes, CreateBranchValues } from '../types/branchOffice/request';
import {
  BranchEcommerceIntegrationTypes,
  BranchTerminalPlan,
  GetBranchResponse,
} from '../types/branchOffice/response';
import { CardBrands } from '../types/enums/card-brands.enum';
import { ConditionsTariffEnum } from '../types/enums/conditions-tariff.enum';
import {
  commerceCodesCardBrandsArray,
  commerceCodesCardBrandsDictionary,
  commerceCodesDictionary,
} from '../utils/data';

const buildBranchCommerceCodesRequest = (
  commerceCodes: { cardBrand: string; acquirer: string; code: string }[] | undefined,
): BranchCommerceCodes | undefined => {
  if (commerceCodes) {
    const formattedCommerceCodes: any = {};

    commerceCodes.forEach(({ cardBrand, acquirer, code }) => {
      const commerceCodeProperty = commerceCodesCardBrandsDictionary[cardBrand as CardBrands];

      formattedCommerceCodes[commerceCodeProperty] = { acquirer, code };
    });

    return formattedCommerceCodes;
  }
};

const buildBranchRequest = (formValues: CreateBranchSchemaType): CreateBranchValues => {
  const request: CreateBranchValues = {
    id: formValues.id,
    fantasyName: formValues.fantasyName,
    addressStreet: formValues.addressStreet,
    addressNumber: formValues.addressNumber,
    addressFlatNumber: formValues.addressFlatNumber,
    addressSolar: formValues.addressSolar,
    addressPlot: formValues.addressPlot,
    businessSubCategory: String(formValues.businessSubCategory),
    city: formValues.city,
    hasNadProducts: formValues.hasNadProducts,
    nadProducts: formValues.hasNadProducts
      ? {
          hasPosSale: formValues.nadProducts.hasPosSale,
          hasImSantander: formValues.nadProducts.hasImSantander,
          hasPixPayments: formValues.nadProducts.hasPixPayments,
          hasAmex: formValues.nadProducts.hasAmex,
          integration: formValues.nadProducts.integration,
          terminals: formValues.nadProducts.terminals,
          terminalPlan: {
            allowInstallments: formValues.nadProducts.terminalPlan.allowInstallments === 'yes',
            includesTips: formValues.nadProducts.terminalPlan.includesTips === 'yes',
            ivaType: formValues.nadProducts.terminalPlan.ivaType,
            commerceCodes: buildBranchCommerceCodesRequest(formValues.nadProducts.terminalPlan.commerceCodes),
          },
        }
      : null,
    hasNewBankAccount: formValues.hasNewBankAccount,
    bankAccounts: formValues.bankAccounts,
    hasPar: formValues.hasPar,
    hasEcommerce: formValues.hasEcommerce,
    ecommerce: formValues.hasEcommerce ? formValues.ecommerce : null,
    tariff: {
      isStandard: formValues.tariff.tariffType === ConditionsTariffEnum.STANDARD,
      acceptMaster: formValues.tariff.acceptMaster === 'SI',
      acceptVisa: formValues.tariff.acceptVisa === 'SI',
    },
  };

  return request;
};

const formatCommerceCodesForForm = (
  terminalPlan: BranchTerminalPlan,
): { cardBrand: string; code: string; acquirer: string }[] => {
  const result = commerceCodesCardBrandsArray.reduce((acc, cardBrand) => {
    const commerceCodeProperty = commerceCodesDictionary.code[cardBrand] as keyof BranchTerminalPlan;
    const commerceCodeAcquirerProperty = commerceCodesDictionary.acquirer[
      cardBrand
    ] as keyof BranchTerminalPlan;

    if (terminalPlan[commerceCodeProperty]) {
      acc.push({
        cardBrand,
        code: terminalPlan[commerceCodeProperty],
        acquirer: terminalPlan[commerceCodeAcquirerProperty],
      });
    }
    return acc;
  }, [] as Array<{ cardBrand: string; code: any; acquirer: any }>);

  return result;
};

const formatEcommerceIntegrationTypes = (integrationTypes: BranchEcommerceIntegrationTypes[]) => {
  return integrationTypes.map((integrationType) => integrationType.id);
};

const buildBranchFormValues = (branchOfficeData: GetBranchResponse) => {
  const result: CreateBranchSchemaType = {
    id: branchOfficeData.id,
    fantasyName: branchOfficeData.fantasyName,
    addressStreet: branchOfficeData.addressStreet,
    addressNumber: branchOfficeData.addressNumber,
    addressFlatNumber: branchOfficeData.addressFlatNumber,
    addressSolar: branchOfficeData.addressSolar,
    addressPlot: branchOfficeData.addressPlot,
    businessSubCategory: branchOfficeData.businessSubCategory,
    city: String(branchOfficeData.cityId),
    state: String(branchOfficeData.city.stateId),
    hasNadProducts: !!branchOfficeData.branchOfficeProducts,
    nadProducts: branchOfficeData.branchOfficeProducts
      ? {
          hasPosSale: !!branchOfficeData.terminals?.length,
          hasImSantander: branchOfficeData.branchOfficeProducts?.hasImSantander,
          integration: branchOfficeData.branchOfficeProducts?.integration,
          hasPixPayments: branchOfficeData.branchOfficeProducts?.hasPixPayments,
          hasAmex: branchOfficeData.branchOfficeProducts?.hasAmex,
          terminals: [],
          terminalPlan: {
            allowInstallments: branchOfficeData.terminalPlan.allowInstallments ? 'yes' : 'no',
            includesTips: branchOfficeData.terminalPlan.includesTips ? 'yes' : 'no',
            ivaType: branchOfficeData.terminalPlan.ivaType,
            commerceCodes: formatCommerceCodesForForm(branchOfficeData.terminalPlan),
          },
        }
      : {
          hasPosSale: false,
          hasImSantander: false,
          integration: '0',
          hasPixPayments: false,
          hasAmex: false,
          terminals: [],
          terminalPlan: {
            allowInstallments: '',
            includesTips: '',
            ivaType: '',
            commerceCodes: [{ cardBrand: '', acquirer: '', code: '' }],
          },
        },
    hasNewBankAccount: false,
    bankAccounts: [],
    hasPar: !!branchOfficeData.hasPar,
    hasNonPresenceCard: !!(branchOfficeData.hasPar || branchOfficeData.ecommerce),
    hasEcommerce: !!branchOfficeData.ecommerce,
    ecommerce: branchOfficeData.ecommerce
      ? {
          integrationType: formatEcommerceIntegrationTypes(branchOfficeData.ecommerce.integrationTypes),
          processor: branchOfficeData.ecommerce.processor,
        }
      : null,
    tariff: {
      tariffType: ConditionsTariffEnum.STANDARD,
      acceptMaster: branchOfficeData.acceptMaster ? 'yes' : 'no',
      acceptVisa: branchOfficeData.acceptVisa ? 'yes' : 'no',
    },
  };

  return result;
};

export const commerceBranchHelper = { buildBranchRequest, buildBranchFormValues };
